<template>
  <div>
    <a-input placeholder="Title" v-model:value="res.title" />
    <a-input placeholder="Content" v-model:value="res.content" />
    <a-input placeholder="Tips" v-model:value="res.tips" />
    <UploadImage @upload="upload" />
    <div style="display: flex">
      <div style="width: 200px" :class="{ 'non-switch': nonSwitch }">
        <a-list size="small" bordered :data-source="res.data">
          <template #renderItem="{ index }">
            <a-list-item>
              <span
                style="cursor:pointer;"
                :style="{ color: cindex == index ? 'red' : '#000' }"
                @click="cindex = index"
              >
                第{{ index }}步
                <span v-if="cindex == index">(正在编辑此步)</span>
              </span>
            </a-list-item>
          </template>
          <template #footer>
            <button @click="add">添加新步骤</button>
          </template>
        </a-list>
      </div>
      <div style="width:1011px;position: relative;min-height:800px">
        <img :src="img" style="width:1000px" />
        <p>{{ img }}</p>
        <div
          class="a jiantou"
          :style="{
            left: `${xt * 10}px`,
            top: `${yt * 10}px`,
            width: `${8 * 10}px`,
            height: `${5.5 * 10}px`,
            transform: `rotate(${rt}deg)`,
          }"
        >
          <img src="~@/assets/jiantou.png" alt="" srcset="" />
        </div>
        <div
          class="a"
          :style="{
            left: `${x1 * 10}px`,
            width: `${w1 * 10}px`,
            top: `${y1 * 10}px`,
            height: `${h1 * 10}px`,
            background: `rgba(0, 0, 255, 0.32)`,
            opacity: goto1 >= 0 ? 1 : 0,
          }"
        ></div>
        <div
          class="a"
          :style="{
            left: `${x2 * 10}px`,
            width: `${w2 * 10}px`,
            top: `${y2 * 10}px`,
            height: `${h2 * 10}px`,
            background: `rgba(0, 255, 0, 0.32)`,
            opacity: goto2 >= 0 ? 1 : 0,
          }"
        ></div>
        <div
          class="a"
          :style="{
            left: `${x3 * 10}px`,
            width: `${w3 * 10}px`,
            top: `${y3 * 10}px`,
            height: `${h3 * 10}px`,
            background: `rgba(255, 0, 0, 0.32)`,
            opacity: goto3 >= 0 ? 1 : 0,
          }"
        ></div>
      </div>
      <div style="flex:1;height:654px;overflow:auto;padding:8px">
        <a-button @click="download = true">导入或导出</a-button>
        <br />
        <br />
        <p><b>提示(箭头)</b></p>
        <p>x轴坐标</p>
        <a-slider v-model:value="xt" :min="0" :max="100" />
        <p>y轴坐标</p>
        <a-slider v-model:value="yt" :min="0" :max="100" />
        <p>角度</p>
        <a-input-number v-model:value="rt" :min="0" :max="359" />
        <p><b>热区1(蓝色)</b></p>
        <p>x轴坐标</p>
        <a-slider v-model:value="x1" :min="0" :max="100" />
        <p>y轴坐标</p>
        <a-slider v-model:value="y1" :min="0" :max="100" />
        <p>宽度</p>
        <a-slider v-model:value="w1" :min="0" :max="100" />
        <p>高度</p>
        <a-slider v-model:value="h1" :min="0" :max="100" />
        <p>goto</p>
        <a-input-number v-model:value="goto1" :min="-2" :max="999" />
        <p><b>热区2(绿色)</b></p>
        <p>x轴坐标</p>
        <a-slider v-model:value="x2" :min="0" :max="100" />
        <p>y轴坐标</p>
        <a-slider v-model:value="y2" :min="0" :max="100" />
        <p>宽度</p>
        <a-slider v-model:value="w2" :min="0" :max="100" />
        <p>高度</p>
        <a-slider v-model:value="h2" :min="0" :max="100" />
        <p>goto</p>
        <a-input-number v-model:value="goto2" :min="-2" :max="999" />
        <p><b>热区3(红色)</b></p>
        <p>x轴坐标</p>
        <a-slider v-model:value="x3" :min="0" :max="100" />
        <p>y轴坐标</p>
        <a-slider v-model:value="y3" :min="0" :max="100" />
        <p>宽度</p>
        <a-slider v-model:value="w3" :min="0" :max="100" />
        <p>高度</p>
        <a-slider v-model:value="h3" :min="0" :max="100" />
        <p>goto</p>
        <a-input-number v-model:value="goto3" :min="-2" :max="999" />
        <!-- <br />
        <br />
        <a-button type="primary" @click="save">保存此步骤</a-button> -->
      </div>
    </div>
    <a-modal v-model:visible="download" title="保存" @ok="savejson">
      <p>导出：将下方内容复制保存即可。(鼠标单击下方输入框三下，可以全选)</p>
      <a-textarea v-model:value="josn" :rows="6" disabled />
      <br />
      <p>导入：若需导入，将要导入的数据粘贴到此，并<b>点击确认</b>即可</p>
      <a-textarea
        placeholder="请将要导入的数据粘贴到此处"
        v-model:value="waitsavejson"
        :rows="6"
      />
    </a-modal>
  </div>
</template>

<script>
import UploadImage from "@/components/UploadImage.vue";
import { message } from "ant-design-vue";

function deepCopy(obj, target) {
  let copyed_objs = []; //此数组解决了循环引用和相同引用的问题，它存放已经递归到的目标对象
  function _deepCopy(target) {
    if (typeof target !== "object" || !target) {
      return target;
    }
    for (let i = 0; i < copyed_objs.length; i++) {
      if (copyed_objs[i].target === target) {
        return copyed_objs[i].copyTarget;
      }
    }
    // let obj = {};
    if (Array.isArray(target)) {
      obj = []; //处理target是数组的情况
    }
    copyed_objs.push({ target: target, copyTarget: obj });
    Object.keys(target).forEach((key) => {
      if (obj[key]) {
        return;
      }
      obj[key] = _deepCopy(target[key]);
    });
    return obj;
  }
  return _deepCopy(target);
}

export default {
  components: {
    UploadImage,
  },
  data: () => ({
    res: {
      title: "顾客1",
      content:
        "我要一杯特大香草拿铁，和马卡龙礼盒四个装分别要栗香拿铁，抹茶拿铁， 覆盆子， 焦糖马奇口味的。 再来一瓶猕猴桃汁。",
      tips:
        "【超】（或【香草风味拿铁】）→【香草风味拿铁】（或【超】）→【食品】→【食品套餐】→【马卡龙4个装】→【栗香拿铁马卡】→【抹茶拿铁马卡】→【覆盆子马卡龙】→【焦糖玛奇马卡】→【瓶装饮料】→【猕猴桃果汁饮】",
      data: [
        {
          img: "",
          rt: 0,
          xt: 0,
          yt: 0,
          x1: 0,
          w1: 10,
          y1: 0,
          h1: 10,
          goto1: -2,
          x2: 15,
          w2: 10,
          y2: 0,
          h2: 10,
          goto2: -2,
          x3: 30,
          w3: 10,
          y3: 0,
          h3: 10,
          goto3: -2,
        },
      ],
    },
    cindex: 0,
    img: "",
    rt: 0,
    xt: 0,
    yt: 0,
    x1: 0,
    w1: 10,
    y1: 0,
    h1: 10,
    goto1: -2,
    x2: 15,
    w2: 10,
    y2: 0,
    h2: 10,
    goto2: -2,
    x3: 30,
    w3: 10,
    y3: 0,
    h3: 10,
    goto3: -2,
    download: false,
    waitsavejson: "",
    nonSwitch: false,
  }),
  watch: {
    cindex(cindex) {
      this.nonSwitch = true;
      message.info(`已切换到第 ${cindex} 步`);
      this.img = this.res.data[cindex].img;
      this.rt = this.res.data[cindex].rt;
      this.xt = this.res.data[cindex].xt;
      this.yt = this.res.data[cindex].yt;
      this.x1 = this.res.data[cindex].x1;
      this.w1 = this.res.data[cindex].w1;
      this.y1 = this.res.data[cindex].y1;
      this.h1 = this.res.data[cindex].h1;
      this.x2 = this.res.data[cindex].x2;
      this.w2 = this.res.data[cindex].w2;
      this.y2 = this.res.data[cindex].y2;
      this.h2 = this.res.data[cindex].h2;
      this.x3 = this.res.data[cindex].x3;
      this.w3 = this.res.data[cindex].w3;
      this.y3 = this.res.data[cindex].y3;
      this.h3 = this.res.data[cindex].h3;
      this.goto1 = this.res.data[cindex].goto1;
      this.goto2 = this.res.data[cindex].goto2;
      this.goto3 = this.res.data[cindex].goto3;
      console.log(this.res.data[cindex]);
      setTimeout(() => {
        this.nonSwitch = false;
      }, 960);
    },
  },
  computed: {
    josn() {
      return JSON.stringify(this.res);
    },
  },
  mounted() {
    setInterval(() => {
      this.save();
    }, 451);
  },
  methods: {
    savejson() {
      console.log(this.waitsavejson);
      try {
        let josn = JSON.parse(this.waitsavejson);
        if ("object" !== typeof josn) throw new Error("not object");
        this.res = josn;
        this.img = josn.data[0]?.img;
        this.rt = josn.data[0].rt;
        this.xt = josn.data[0].xt;
        this.yt = josn.data[0].yt;
        this.x1 = josn.data[0].x1;
        this.w1 = josn.data[0].w1;
        this.y1 = josn.data[0].y1;
        this.h1 = josn.data[0].h1;
        this.x2 = josn.data[0].x2;
        this.w2 = josn.data[0].w2;
        this.y2 = josn.data[0].y2;
        this.h2 = josn.data[0].h2;
        this.x3 = josn.data[0].x3;
        this.w3 = josn.data[0].w3;
        this.y3 = josn.data[0].y3;
        this.h3 = josn.data[0].h3;
        this.goto1 = josn.data[0].goto1;
        this.goto2 = josn.data[0].goto2;
        this.goto3 = josn.data[0].goto3;
        console.log(this.res.data[0].x1);
        console.log(this.res);
        setTimeout(() => {
          this.cindex = 0;
          this.download = false;
          this.waitsavejson = "";
          message.info("导入成功！");
        }, 0);
      } catch (error) {
        console.error(error);
        message.error("导入失败，请核对");
      }
    },
    upload(url) {
      console.log(url);
      this.img = url;
    },
    save() {
      this.res.data[this.cindex] = {
        img: this.img,
        rt: this.rt,
        xt: this.xt,
        yt: this.yt,
        x1: this.x1,
        w1: this.w1,
        y1: this.y1,
        h1: this.h1,
        x2: this.x2,
        w2: this.w2,
        y2: this.y2,
        h2: this.h2,
        x3: this.x3,
        w3: this.w3,
        y3: this.y3,
        h3: this.h3,
        goto1: this.goto1,
        goto2: this.goto2,
        goto3: this.goto3,
      };

      //   message.info(`修改第 ${this.cindex} 个步骤成功`);
    },
    add() {
      this.res.data.push({
        img: "",
        rt: 0,
        xt: 0,
        yt: 0,
        x1: 0,
        w1: 10,
        y1: 0,
        h1: 10,
        x2: 15,
        w2: 10,
        y2: 0,
        h2: 10,
        x3: 30,
        w3: 10,
        y3: 0,
        h3: 10,
        goto1: -2,
        goto2: -2,
        goto3: -2,
      });

      message.info("添加新步骤成功");
    },
  },
};
</script>

<style>
.a {
  position: absolute;
  background: rgba(0, 0, 255, 0.32);
}

.jiantou {
  pointer-events: none;
}

.non-switch {
  pointer-events: none;
  opacity: 0.5;
}
</style>
