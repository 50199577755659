<template>
  <a-upload-dragger
    v-model:fileList="fileList"
    name="image"
    :multiple="true"
    action="https://plus.hi-finance.com.cn/api/upload/index"
    @change="handleChange"
  >
    <p class="ant-upload-drag-icon">
      <inbox-outlined></inbox-outlined>
    </p>
    <p class="ant-upload-text">Click or drag file to this area to upload</p>
    <p class="ant-upload-hint">
      Support for a single or bulk upload. Strictly prohibit from uploading
      company data or other band files
    </p>
  </a-upload-dragger>
</template>

<script setup>
import { InboxOutlined } from "@ant-design/icons-vue";
import { message } from "ant-design-vue";
import { defineComponent, defineEmit, ref } from "vue";

ref: fileList = [];

const emit = defineEmit(["upload"]);

const handleChange = (info) => {
  const status = info.file.status;
  if (status !== "uploading") {
    console.log(info.file, info.fileList);
  }
  if (status === "done") {
    message.success(`${info.file.name} file uploaded successfully.`);
    emit("upload", info.fileList[info.fileList.length - 1].response?.img_url);
  } else if (status === "error") {
    message.error(`${info.file.name} file upload failed.`);
  }
};
</script>
